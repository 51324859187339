<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white min-vh-100"
         id="kt_login">
      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div class="d-flex justify-content-center flex-column-fluid mt-10 mt-lg-0" :class="{'flex-center': $route.name !== 'verification'}">
          <div class="login-form login-signin">
            <div v-if="isLogin || $route.name === 'verification'" class="text-center">
              <div class="orbi-logo__wrapper">
                <img class="orbi-logo" src="media/custom/orbi-icon.png" alt="Logo"/>
              </div>
              <h3 class="font-size-h1">{{ title }}</h3>
            </div>

            <div class="auth-form">
              <router-view @title="title = $event"></router-view>
            </div>
            <div v-if="isLogin" class="mobile-app-store-wrapper d-flex justify-content-center align-items-center mt-5">
              <b-link href="https://play.google.com/store/apps/details?id=com.orbi.myorbi" target="_blank">
                <img class="mobile-app-store-logo mr-5" src="media/custom/google-play.png" alt="Logo"/>
              </b-link>
              <img class="mobile-app-store-logo" src="media/custom/app-store.png" alt="Logo"/>
            </div>
            <div v-if="isLogin || $route.name === 'verification'" class="d-flex justify-content-center mt-5">
              <span
                  v-for="language in languages"
                  class="text-dark-50 text-hover-primary font-size-sm cursor-pointer mx-3"
                  @click="changeLanguage(language.lang)"
              >
                {{ language.name }}
              </span>
            </div>
            <div v-if="isLogin" class="text-center mt-5">
              <a href="/terms" target="_blank" class="text-dark-50 text-hover-primary cursor-pointer">
                {{ $t('label.terms_and_conditions') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import i18nService from '@/core/services/i18n.service.js';

export default {
  name: 'auth',
  data: () => ({
    title: null,
    languages: i18nService.languages
  }),
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + 'media/bg/bg-4.jpg';
    },
    isLogin() {
      return this.$route.name === 'login';
    }
  },
  methods: {
    changeLanguage(language) {
      i18nService.setActiveLanguage(language);

      this.$emit('language-changed', this.languages.find(val => {
            return val.lang === language;
          })
      );

      this.$i18n.locale = language;
    }
  }
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style lang="scss" scoped>
.mobile-app-store-wrapper {
  img {
    height: 38px;
  }
}

@media (min-width: 1024px) {
  .auth-form::v-deep {
    width: 450px;
  }
}
</style>